ul {
    list-style-type: none;
    overflow: hidden;
    background-color: #333;
    margin-top: 0px;
    height: 30px;
  }
  
  li {
    float: left;
  }
  
  li a, .dropbtn {
    display: inline-block;
    color: white;
    text-align: center;
    font-size: medium;
    padding-top: 5%;
    padding-bottom: 5%;
    text-decoration: none;
  }
  
  li a:hover, .dropdown:hover .dropbtn {
    background-color: red;
  }
  
  li.dropdown {
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  #logo {
    position: absolute;
    left: 0px;
    height: 30px;
  }
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  
  .dropdown-content a:hover {background-color: #f1f1f1;}
  
  .dropdown:hover .dropdown-content {
    
    display: block;
  }

   /* Customize the label (the container) */
.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 2px;
    left: 4px;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
.rsssquare{ 
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #FFA500 ;
    display: inline-block;
}
.twittersquare{
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #00BFFF  ;
  display: inline-block;
}
.youtubesquare{
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #FF0000 ;
  display: inline-block; 
}
  .copi{
    position: absolute;
    right: 5px;
    font-size: small;
    color: white;
  }